<template>
    <v-card
        width="850"
        
        class="mx-auto pa-4 mt-4"        
        elevation="2"
        outlined
    >
        <v-tabs
            v-model="tab"
            grow
        >
            <v-tab
                v-for="item in tabOptions"
                :key="item"
            >
                {{ item }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab"> 
            <v-tab-item>
                <edit-view-subjects 
                    v-bind:subjects="subjects"
                    v-on:update-subjects="initialize"
                ></edit-view-subjects>                  
            </v-tab-item>
            
            <v-tab-item>
                <subjects-student-assignment
                    v-bind:subjects="subjects"
                    v-bind:teachers="teachers"
                ></subjects-student-assignment>                         
            </v-tab-item>

            <v-tab-item>
                <batch-student-subject-assignment
                    v-bind:subjects="subjects"
                    v-bind:teachers="teachers"
                    v-bind:formLevels="formLevels"
                    v-bind:formClasses="formClasses"
                    v-bind:students="students"
                    v-on:show-overlay="showOverlay"
                ></batch-student-subject-assignment>
            </v-tab-item>
               
        </v-tabs-items>
         
       <v-overlay
            absolute
            :value="overlay"
            opacity="0.7"
        >
            <v-progress-circular 
                indeterminate 
                size="64"                    
                color="white"
            ></v-progress-circular>   
        </v-overlay>

         
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import EditViewSubjects from './AdminSubjectsEditView';
import SubjectsStudentAssignment from './AdminSubjectsStudentAssignment';
import BatchStudentSubjectAssignment from './AdminSubjectsStudentBatch';
export default {
    created: function () {
        this.initialize();
    },

    components: {
        EditViewSubjects,
        SubjectsStudentAssignment,
        BatchStudentSubjectAssignment,
    },
    
    data: () => ({
        
        subjects: [],
        teachers: [],
        formLevels: [],
        formClasses: [],
        students: [],
        tabOptions: [
            'Edit/View Subjects', 'Subject Student Assignment', 'Batch Assignment'
        ],
        tab: null,
        overlay: false,
    }),

    methods: {
        ...mapActions({            
            getSubjects: 'admin/getSubjects',            
            getEmployees: 'admin/getEmployees',
            getFormLevels: 'admin/getFormLevels',
            getFormClasses: 'admin/getFormClasses',
            getStudents: 'admin/getStudents',           
        }),

        async initialize () {
            this.overlay = true;
            const promiseSubjects = this.getSubjects();
            const promiseEmployees = this.getEmployees();
            const promiseFormLevels = this.getFormLevels();
            const promiseFormClasses = this.getFormClasses();
            const promiseStudents = this.getStudents();

            Promise.all([
                promiseSubjects, 
                promiseEmployees, 
                promiseFormLevels, 
                promiseFormClasses,
                promiseStudents,
            ])
            .then((responses) => {
                this.subjects = [...responses[0].data];                  

                this.teachers = responses[1].data.map(value => {
                    return {
                        name: value.first_name + ' ' + value.last_name,
                        display_name: value.first_name[0] + '. ' + value.last_name,                        
                        id: value.id
                    }
                });

                responses[2].data.forEach(value => {
                    this.formLevels.push(value.form_level)
                })
                
                this.formClasses = responses[3].data.filter(value => {
                    if(value.form_level) return value;
                });

                this.students = responses[4].data.map(value => {
                    return {
                        id: value.id,
                        name: value.last_name + ' ' + value.first_name,
                        form_level: value.form_level,
                        class_id: value.class_id
                    }
                });

                this.overlay = false;
               
            })
            .catch((error) => {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.overlay = false;
            }) 
        },

        showOverlay (display = false) {
            this.overlay = display;            
        }

    }
}
</script>