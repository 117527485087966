<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="students"
            :search="search"
            sort-by="updatedAt"
            sort-desc
            class="elevation-1"
            fixed-header
            height="60vh"
            :loading="loading"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Students</v-toolbar-title>
                    <v-divider
                        class="ml-4 mr-8"
                        inset
                        vertical
                    ></v-divider>

                    <admin-upload-students
                        v-on:update-students="initialize"
                    ></admin-upload-students>

                    <v-dialog
                        v-model="dialogReport"
                        :max-width="maxWidth"
                        persistent
                    >
                        <v-card
                            v-if="!picturePreview"
                            height="80vh"
                        >
                            <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>

                        </v-card>

                        <v-img
                            v-else
                            max-width="200"
                            :src="src"
                            class="mx-auto ma-6"
                        ></v-img>

                        <v-container
                            fluid
                            class="pa-0"
                        >
                            <v-sheet>
                                <v-row
                                    justify="end"
                                    class="ma-0"
                                >

                                    <v-btn
                                        color="primary"
                                        dark
                                        @click="closeRegistrationForm"
                                        class="my-2 mr-4"
                                    >
                                        Close
                                    </v-btn>

                                </v-row>
                            </v-sheet>
                        </v-container>

                    </v-dialog>

                    <v-dialog
                        v-model="dialogResetPassword"
                        max-width="600px"
                        persistent
                    >
                        <v-card>
                            <v-card-title
                                class="subtitle-1"
                                v-text="dialogResetTitle"
                            >
                            </v-card-title>
                            <v-card-text
                                v-if="resetProgress"
                            >
                                <v-progress-linear
                                    color="primary"
                                    indeterminate
                                    rounded
                                    height="6"
                                ></v-progress-linear>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    outlined
                                    @click="closeResetPassword"
                                    small
                                    v-if="cancelButton"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="resetPasswordConfirm"
                                    class="ml-6"
                                    small
                                    v-if="okButton"
                                >
                                    Ok
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    dark
                                    @click="closeResetPassword"
                                    class="ml-6"
                                    small
                                    v-if="okCloseButton"
                                >
                                    Ok
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        v-model="dialogEdit"
                        max-width="500px"
                        persistent
                    >
                        <v-card ref="form">
                            <v-card-title>
                                Edit Student
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="first_name"
                                                v-model="editedItem.first_name"
                                                label="First Name"
                                                hide-details="auto"
                                                :rules="rules"
                                                @blur="updateStudent"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="last_name"
                                                v-model="editedItem.last_name"
                                                label="Last Name"
                                                hide-details="auto"
                                                :rules="rules"
                                                @blur="updateStudent"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="formattedDatefns"
                                                        label="Date of Birth"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        hide-details
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    ref="picker"
                                                    v-model="editedItem.date_of_birth"
                                                    min="1950-01-01"
                                                    @change="saveDate"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                ref="birth_certificate_no"
                                                v-model="editedItem.birth_certificate_no"
                                                label="Birth Cerficate Pin"
                                                hide-details="auto"
                                                :rules="rules"
                                                @blur="updateStudent"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                     <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="editedItem.class_id"
                                                :items="formClasses"
                                                chips
                                                deletable-chips
                                                hide-details
                                                class="pt-0 mt-0"
                                                @change="updateStudent"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <div
                                    class="pl-6"
                                >
                                    {{ saveStatus }}
                                    <v-progress-circular
                                        indeterminate
                                        v-show="saving"
                                        size="16"
                                        width="3"
                                        class="ml-2"
                                    ></v-progress-circular>
                                    <v-icon
                                        small
                                        v-show="saved"
                                        class="ml-2"
                                    >
                                        mdi-check-all
                                    </v-icon>
                                    <v-icon
                                        small
                                        color="red"
                                        v-show="saveError"
                                        class="ml-2"
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    outlined
                                >
                                    Save
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="closeEdit"
                                    outlined
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-spacer></v-spacer>

                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        class="ml-4"
                        clearable
                    ></v-text-field>
                </v-toolbar>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            medium
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="showRegistrationForm(item)"
                        >
                            mdi-file-outline
                        </v-icon>
                    </template>
                    <span>Registration Form</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            medium
                            @click="showEdit(item)"
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        >
                            mdi-account-edit
                        </v-icon>
                    </template>
                    <span>Edit</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            medium
                            @click="showResetPassword(item)"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-lock-reset
                        </v-icon>
                    </template>
                    <span>Reset Password</span>
                </v-tooltip>


                <v-menu
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            medium                           
                            v-bind="attrs"
                            v-on="on"
                            class="ml-3"
                        >
                            mdi-dots-vertical
                        </v-icon>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(file, index) in files"
                            :key="index"
                            link
                            @click="displayFile(item, file)"
                        >
                            <v-list-item-icon>
                                <v-icon
                                    color="primary"
                                    v-if="item[file.model]"
                                >
                                    mdi-cloud-check
                                </v-icon>
                                <v-icon
                                    v-else
                                    color="red"
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ file.title }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

            </template>
        </v-data-table>
        <div class="text-right pt-4 caption">
            Total Number of Students: {{ totalRecords }}
        </div>

    </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AdminUploadStudents from './AdminUploadStudents';
import { format, parseISO } from 'date-fns';
export default {
    name: 'AdminDashboard',
    components: {
        AdminUploadStudents,
    },

    created () {
        this.initialize();
    },
    data: () => ({

        headers:[
            { text: 'ID', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Class', value: 'class_id' },
            { text: 'Date of Birth', value: 'formatted_date_of_birth' },
            { text: 'Birth Certificate Pin', value: 'birth_certificate_no' },
            { text: 'Actions', value: 'actions', sortable: false },
            //{ text: 'Updated At', value: 'updatedAt', sortable: true },
            // { text: 'Progress', value: 'progress' },
        ],
        students: [],
        search: '',
        totalRecords: '-',
        loading: true,
        dialogReport: false,
        picturePreview: false,
        dialogResetPassword: false,
        dialogEdit: false,
        src: '',
        selectedIndex: -1,
        selectedRecord: {},
        defaultRecord: {
            id: '',
            name: '',
            first_name: '',
            last_name: '',
            classId: '',
            dob: '',
            birth_certificate_no: '',
            progress: '',
            updatedAt: '',
        },
        editedItem: {},
        dialogResetTitle: '',
        cancelButton: true,
        okButton: true,
        okCloseButton: false,
        resetProgress: false,
        menu: false,
        date: null,
        rules: [
            value => !!value || 'Required'
        ],
        saving: false,
        saved: false,
        saveError: false,
        saveStatus: '',
        formClasses: [],
        files: [
            {title: 'Birth Certificate', model: 'birth_certificate'},
            {title: 'Immunization Card', model: 'immunization_card'},
            {title: 'SEA Placement Slip', model: 'sea_slip'},
            {title: 'Passport Photo', model: 'picture'},
        ],
        maxWidth: '',
    }),

    computed: {
      formattedDatefns () {
          return this.editedItem.date_of_birth ? format(parseISO(this.editedItem.date_of_birth), 'dd-MMM-yyyy') : '';
      }

    },

    watch: {
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        date (val) {
            this.editedItem.date_of_birth = val;
        }
    },
    methods: {
        ...mapActions({
            getStudents: 'admin/getStudents',
            resetPassword: 'admin/resetPassword',
            postStudent: 'admin/postStudent',
            getFormClasses: 'admin/getFormClasses',
        }),

        ...mapMutations({
            setEditedStudent: 'admin/setEditedStudent',
        }),

        async initialize () {
            let studentRecord = null, response = null;
            this.students = [];
            try {
                const studentsPromise = this.getStudents();
                const formClassesPromise = this.getFormClasses();

                response = await studentsPromise;
                let records = response.data;
                this.totalRecords = records.length;
                // console.log(records);
                records.forEach(record => {
                    studentRecord = {};
                    studentRecord.name = record.last_name + ', ' + record.first_name
                    studentRecord = Object.assign(studentRecord, record);
                    studentRecord.formatted_date_of_birth = this.formatDate(studentRecord.date_of_birth);
                    studentRecord.birth_certificate = record.file_birth_certificate;
                    studentRecord.immunization_card = record.file_immunization_card;
                    studentRecord.sea_slip = record.file_sea_slip;
                    studentRecord.picture = record.picture;
                    this.students.push(studentRecord)
                })

                response = await formClassesPromise;
                response.data.forEach(value => {
                    this.formClasses.push(value.id);
                });

                this.loading = false

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }

        },

        closeForm () {
            this.dialogReport = false
            this.$nextTick(() => {
                this.selectedRecord = Object.assign({}, this.defaultRecord)
                this.selectedIndex = -1
            })
        },

        closeResetPassword () {
            this.dialogResetPassword = false
            this.dialogResetTitle = ''
            this.resetProgress = false;
            this.cancelButton = true;
            this.okButton = true;
            this.okCloseButton = false;
            this.$nextTick(() => {
                this.selectedRecord = Object.assign({}, this.defaultRecord)
                this.selectedIndex = -1
            })
        },

        showRegistrationForm (item) {
            this.selectedIndex = this.students.indexOf(item)
            this.selectedRecord = Object.assign({}, item)
            console.log(this.selectedRecord);
            this.maxWidth = "80vw";
            this.picturePreview = false;
            this.src = process.env.VUE_APP_API_URI + '/api/registration-form/' + this.selectedRecord.id;
            this.dialogReport = true;
        },

        showResetPassword (item) {
            this.selectedIndex = this.students.indexOf(item)
            this.selectedRecord = Object.assign({}, item)
            this.dialogResetTitle = 'Are you sure you want to reset ' + this.selectedRecord.name + ' password?'
            this.dialogResetPassword = true
        },

        showEdit (item) {
            this.editedItem = Object.assign({}, item);
            
            if(this.editedItem.date_of_birth){
                this.editedItem.date_of_birth = format(parseISO(item.date_of_birth), 'yyyy-MM-dd')
            }
            
            this.dialogEdit = true;
        },

        async updateStudent () {
            this.saveStatus = "Updating";
            this.saveError = false;
            this.saved = false;
            this.saving = true;
            // console.log(this.editedItem);
            const {
                id: studentId,
                first_name: firstName,
                last_name: lastName,
                date_of_birth: dateOfBirth,
                birth_certificate_no: birthCertificateNo,
                class_id: classId,
            } = this.editedItem;

            this.setEditedStudent({
                id: studentId,
                first_name: firstName,
                last_name: lastName,
                date_of_birth: dateOfBirth,
                birth_certificate_no: birthCertificateNo,
                class_id: classId
            });
            //console.log(this.editedItem);
            // console.log(this.editedItem);
            try {
                let response = await this.postStudent();
                console.log(response.status);
                this.initialize();
                this.saveStatus = "Updated Successfully."
                this.saving = false;
                this.saved = true;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.saveStatus = "Error Occured."
                this.saving = false;
                this.saveError = true;
            }
        },

        resetPasswordConfirm () {
            //console.log(this.students[this.selectedIndex]);
            this.dialogResetTitle = 'Resetting ' + this.selectedRecord.name + ' password'
            this.resetProgress = true;
            this.cancelButton = false;
            this.okButton = false;
            let id = this.students[this.selectedIndex].id;
            this.resetPassword(id)
            .then(response => {
                console.log(response)
                if(response.status === 200){
                    this.dialogResetTitle = this.selectedRecord.name + ' password reset successfully.'
                    this.resetProgress = false;
                    this.cancelButton = false;
                    this.okButton = false;
                    this.okCloseButton = true;
                }
            })
            .catch(error => {
                if(error.response) console.log(error.response);
                else console.log(error)
            })
            //this.closeResetPassword()
        },

        closeRegistrationForm(){
            this.src = ''
            this.dialogReport = false
            this.editedItem
        },

        formatDate(date){
            if(!date) return null;
            return format(parseISO(date), 'dd-MMM-yyyy');
        },

        saveDate(date){
            this.$refs.menu.save(date);
            this.updateStudent();
        },

        closeEdit(){
            this.dialogEdit = false;
        },

        displayFile (record, file) {
            console.log(record);
            console.log(record[file.model]);
            let src = record[file.model]
            if(src && file.model != 'picture'){
                this.maxWidth = "80vw";
                this.src = src;
                this.picturePreview = false;
                this.dialogReport = true;
            }
            else if(src && file.model == 'picture'){
                this.maxWidth = "300";
                this.picturePreview = true;
                this.src = src;
                this.dialogReport = true;
            }

        }

    }
}
</script>