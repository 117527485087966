<template>
    <v-row
        justify="center"
    >        
        <v-col>        
            <v-card
                class="mx-auto pa-0"
                width="950"                
            >
                <v-card-title
                    class="d-flex white--text  py-2 px-0 primary"
                >
                    <v-spacer></v-spacer>
                    <div class="caption font-weight-light text-uppercase">Mark Sheet</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="white"
                        @click="closeMarkSheet"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>            
                </v-card-title>
                
                <v-row class="px-4">
                    <v-col class="py-0">
                        <Header/>
                        <v-divider></v-divider>
                        <MarkSheet/>
                        <v-row class="mt-3 mr-4">
                            <v-spacer></v-spacer>
                            <div class="caption">
                                Total Students: {{ totalStudents }}
                            </div>
                        </v-row>
                    </v-col>
                </v-row>
                
                <v-overlay
                    absolute
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9"
                    class="pa-5"
                >                    
                    <Lesson/>
                    <lesson-admin></lesson-admin> 
                        
                    <v-progress-circular 
                        transition="fade-transition" 
                        indeterminate 
                        size="64"
                        v-show="loading"
                        color="blue"
                    ></v-progress-circular> 
                </v-overlay>

                <v-overlay
                    absolute
                    :value="overlayError"
                    color="grey lighten-5"
                    opacity="0.9"
                >
                    <v-card
                        class="mx-auto"
                        width="400"
                        light
                    >
                       <v-card-title class="headline red--text">
                            <v-icon color="red" class="pr-2">mdi-alert-circle-outline</v-icon>
                            Error
                        </v-card-title>
                       <v-card-text class="text-h6 text-weight-light">
                           {{ error.response }}
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn 
                                color="primary" 
                                outlined
                                @click="closeError"
                            >Ok</v-btn>
                        </v-card-actions> 
                    </v-card>
                </v-overlay>
               
            </v-card>
        </v-col>       
    </v-row>    
</template>

<script>
import Header from './EnterMarksHeader';
import Lesson from './EnterMarksLesson';
import LessonAdmin from './EnterMarksLessonAdmin';
import MarkSheet from './EnterMarksSheet';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'EnterMarks',

    created: function () {
        this.initialize();
    },
    
    components: {
        Header,
        Lesson,
        LessonAdmin,
        MarkSheet,
    },

    computed: {
        ...mapGetters({
            overlay: 'termReports/getMarkSheetOverlay',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            mainMenuButton: 'termReports/getMainMenuButton',
            loading: 'termReports/getMarkSheetLoading',
            totalStudents: 'termReports/getSubjectStudentsRegistered',
            overlayError: 'enterMarks/getOverlayError',
            error: 'enterMarks/getError',   
        }), 
    },

    data: () => ({
        userTimetable: [],
    }),

    methods: {
        ...mapMutations({
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            setOverlay: 'termReports/setMarkSheetOverlay',
            setLoading: 'termReports/setMarkSheetLoading',
            setExpand: 'termReports/setExpandLessonOptions',
            welcomeMessage: 'termReports/setWelcomeMessage',
            table2Records: 'termReports/setTable2Records',
            setStudentName: 'termReports/setMarkSheetStudentSelected',
            setOverlayError: 'enterMarks/setOverlayError',
        }),

        initialize () {
            this.setExpand(false);            
            this.$nextTick(() => {
                this.setOverlay(true);
                this.setLoading(true);
            })
        },

        setLessonSelected(e){
            this.loading = true;
            console.log(e);
        },
        
        closeMarkSheet(){
            this.setOverlay(true);
            this.table2Records([]);
            this.setStudentName('');
            // this.setExpand(true);            
        },

        closeError () {
            this.setOverlayError(false);
        }
    }
}
</script>