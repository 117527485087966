<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                Post Term Reports
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details                     
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="terms"
                :search="search"
                :loading="loading"
                loading-text="Loading... Please wait"
                fixed-header
                height="60vh" 
            >
                <template
                    v-slot:top
                >
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                        persistent
                    >
                        <v-card
                            v-show="cardPostReport"
                        >
                            <v-card-title></v-card-title>                            
                            <v-card-text
                                class="text-h6"
                            >
                                {{ formText }}
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    @click="cancel"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    outlined
                                    @click="removeReports"
                                >
                                    No
                                </v-btn>
                                <v-btn
                                    text
                                    outlined
                                    @click="postReports"
                                >
                                    Yes
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                        <v-expand-transition>
                            <v-card
                                color="primary"
                                dark
                                v-show="expand"
                                class="pa-4"
                            >
                                <v-card-text>
                                    {{ postingText }}
                                <v-progress-linear
                                    indeterminate
                                    color="white"
                                    class="mb-0 mt-2"
                                ></v-progress-linear>
                                </v-card-text>
                            </v-card>
                        </v-expand-transition>
                    </v-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                        text
                        outlined
                        @click="editTerm(item)"
                    >
                        <v-icon
                            small
                            class="mr-2"
                        >
                            mdi-pencil
                        </v-icon>
                        Edit
                    </v-btn>
                </template>
            </v-data-table>
            
        </v-card>
        <v-snackbar
            v-model="snackbar"
            color="primary"
        >
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        headers:[
            {
                text: 'Academic Year',                
                value: 'year'
            },
            {
                text: 'Term',                
                value: 'term'
            },
            {
                text: 'Posted',                
                value: 'posted'
            },
            {
                text: 'Date Posted',                
                value: 'date_posted'
            },
            {
                text: 'Edit',                
                value: 'actions',
                sortable: false,
            },
        ],
        terms: [],
        search: '',
        loading: true,
        dialog: false,
        editedItem: {},
        snackbar: false,
        snackText: '',
        cardPostReport: true,
        expand: false,
        postingText: '',
    }),

    created(){
        //console.log('post term reports created');
        this.initialize();
    },

    computed: {
        ...mapGetters({
            menuOptions: 'admin/getMenuOptions',
        }),
        formText () {
            return 'Post ' + this.editedItem.year + ' Term ' + this.editedItem.term + ' reports?';
        },
        // snackText () {
        //     return this.editedItem.year + ' Term ' + this.editedItem.term + ' reports posted';
        // },
        // postingText () {
        //     return 'Posting reports please stand by'
        // }
    },

    methods: {
        ...mapActions({
            getTermReportsPosted: 'admin/getReportsPosted',
            postTermReports: 'admin/postTermReports',
        }),
        ...mapMutations({
            setEditedTerm: 'admin/setEditedTerm',
        }),

        async initialize(){
            try {
                const { data } = await this.getTermReportsPosted();
                console.log(data);
                this.setTermRecords(data);
                this.loading = false;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        setTermRecords(data){
            this.terms = data.map(term => {
                let reportPosted = 'posted';
                if(term.posted == 0) reportPosted = 'not posted';
                return {
                    year: term.year + '-' + (parseInt(term.year) + 1),
                    term: term.term,                    
                    posted: reportPosted,
                    date_posted: term.date_posted
                } 
            })
        },
        editTerm(item){
            console.log(item);
            this.editedItem = item;
            this.cardPostReport = true;
            this.dialog = true;
        },
        cancel(){
            this.dialog = false;
        },
        async postReports(){
            this.cardPostReport = false;
            this.postingText = 'Posting reports please stand by'
            this.$nextTick(()=>{
                this.expand = true;
            })
            let termRecord = Object.assign({}, this.editedItem);
            termRecord['posted'] = 1;
            let date = new Date();
            termRecord['date_posted'] = date.toLocaleString("sv-SE");
            console.log(termRecord);
            this.setEditedTerm(termRecord);            
            try {
                await this.postTermReports();
                this.editedItem['posted'] = 'posted';
                this.editedItem['date_posted'] = date.toLocaleString("sv-SE");
                this.expand = false;
                this.$nextTick(()=>{
                    this.dialog = false;
                })
                
                setTimeout(() => {
                    this.snackText = this.editedItem.year + ' Term ' + this.editedItem.term + ' reports posted'                    
                    this.snackbar = true;
                })
                //console.log(response)
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },
        async removeReports(){
            this.cardPostReport = false;
            this.postingText = 'Removing reports please stand by'
            this.$nextTick(()=>{
                this.expand = true;
            })
            let termRecord = Object.assign({}, this.editedItem);
            termRecord['posted'] = 0;            
            termRecord['date_posted'] = null;
            //console.log(termRecord);
            this.setEditedTerm(termRecord);            
            try {
                await this.postTermReports();
                this.editedItem['posted'] = 'not posted';
                this.editedItem['date_posted'] = null;
                this.expand = false;
                this.$nextTick(()=>{
                    this.dialog = false;
                })
                
                setTimeout(() => {
                    this.snackText = this.editedItem.year + ' Term ' + this.editedItem.term + ' reports removed'
                    this.snackbar = true;
                })
                //console.log(response)
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        }
    }
}
</script>