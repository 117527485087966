<template>
    <v-btn        
        text
        v-on:click="logOut"
      >
        <span :class="buttonClass">Logout</span>
        <v-icon
            :color="iconColor"
        >
            mdi-logout
        </v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            admin: 'auth/getAdmin',
        }),

        iconColor () {
            if(this.admin) return 'primary';
            return 'secondary';
        },

        buttonClass () {
            if(this.admin) return 'mr-2 primary--text'
            return 'mr-2 secondary--text'
        }


    },

    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            setOverlay: 'auth/setOverlay',
            setStep: 'auth/setStep',
            setExpand: 'auth/setExpand',
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            editViewTermReports: 'termReports/setEditViewTermReports',
            welcomeMessage: 'termReports/setWelcomeMessage',
            setProgressOverlay: 'admin/setProgressOverlay',            
        }),

        async logOut () {            
            this.setOverlay(true);
            this.setProgressOverlay(true);
            try {
                await this.signOut();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setOverlay(false);
            this.setProgressOverlay(false);
            this.setStep(1);
            this.setExpand(true);
            this.$router.replace('/')
        }

        
    }
}
</script>