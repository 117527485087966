export default {
    namespaced: true,

    state: {
        overlayError: false,
        error: {},
    },

    getters: {
        getOverlayError (state) {
            return state.overlayError;
        },

        getError (state) {
            return state.error;
        }
    },

    mutations: {
        setOverlayError (state, value) {
            state.overlayError = value;
        },

        setError (state, value) {
            state.error = value;
        }
    }
}