<template>
    <v-col class="pr-0 pb-0">
        <v-row class="mx-3">
            <v-card
                flat
                width="250"
                align="center"
                justify="center"
                color="grey lighten-5"
                dark
                class="mb-0"
            >
                <span 
                    class="mr-3 text-subtitle-1 green--text"                                         
                > {{ studentName }}</span>                                 
                <span 
                    class="text-subtitle-1 green--text"
                >{{ classId }}</span>
            </v-card>
        </v-row>

        <v-row class="mx-3">
            <v-card                                    
                width="250"
                flat
                color="grey lighten-5"                
                dark                                    
            >
                <v-row
                    class="px-3"
                >
                    <v-col
                        align="center"
                        justify="center"
                        v-for="item in academicPeriod"
                        :key="item.title"
                        class="pa-0"
                    >
                        <div 
                            class="text-caption blue--text"
                        >{{item.title}}</div>
                        <div 
                            class="text-caption green--text"
                        >{{item.value}}</div>
                    </v-col>
                </v-row>
            </v-card>                                 
        </v-row>

        <v-row class="mx-3">
            <v-card                
                width="250"
                flat
            >
                <div>
                    <v-list dense class="pt-0">
                        <v-list-item
                            v-for="item in recordSummary"
                            :key="item.title"
                        >
                            <v-list-item-content class="pt-0 pb-0">
                                <v-list-item-title 
                                    class="text-caption"
                                >
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-card
                                width="60"                                                                                                       
                                flat
                                class="pb-0"
                            >
                                <v-text-field
                                    v-if="!item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    dense
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="25"
                                    class="text-caption pa-0"
                                    outlined
                                    type="number"
                                    @keypress="validateInteger"
                                ></v-text-field>

                                <v-select
                                    v-if="item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled"
                                    :items="grades"                                    
                                    item-value="grade"
                                    v-model="studentRecord[item.model]"
                                    hide-details                                    
                                    outlined
                                    class="text-caption text-center"
                                    @change="updateRecord"                                   
                                >
                                    <template v-slot:selection="{item}">
                                        {{ item.grade }}
                                    </template>

                                    <template
                                        v-slot:item=data
                                    > 
                                        {{ data.item.grade}} {{ data.item.title }}
                                    </template> 
                                </v-select>
                            </v-card>
                        </v-list-item>
                    </v-list>
                </div>                                     
            </v-card>
        </v-row>

        <v-row class="mx-0 mt-1">
            <v-card                                
                class="mb-2 pl-4"
                flat
                :width="width"
            >                
                <v-btn                        
                    outlined
                    color="primary"                        
                    @click="searchStudent"
                    small                        
                    block
                >
                    Search Student
                    <v-icon
                        medium
                        class="ml-2"
                    >mdi-magnify</v-icon>
                </v-btn>                
            </v-card>
        </v-row>

        <v-row
            class="mt-3 mx-0"
        >
            <v-card
                :width="width"
                flat
                class="pl-4"
            >
                <v-btn                
                    color="primary"
                    block
                    small
                    @click="nextClass"
                >
                    Next Class
                </v-btn>
            </v-card>
        </v-row>

         <v-row
            class="mt-4 mx-0"
        >
            <v-card
                flat
                :width="width"
                class="d-flex justify-space-between pl-4"
            >
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"                
                    color="primary"
                    @click="previousRecord"
                    :disabled="!pagination.prev_page"                
                >
                    <div class="d-flex flex-column"> 
                        <span>PREV</span>
                    </div>                                         
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2"
                    width="15"
                    height="30"
                    outlined
                    disabled                
                    text                
                >
                    <span class="primary--text font-weight-bold">{{pagination.current_page}} of {{ pagination.last_page}}</span>                                                        
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"
                    dark
                    color="primary"
                    @click="nextRecord"
                    :disabled="!pagination.next_page"
                >
                    <div class="d-flex flex-column">
                        <span>NEXT</span>
                    </div>                                         
                </v-btn>
            </v-card>    
        </v-row>

        <v-overlay
            absolute
            :value="overlay"
            color="secondary"
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="table1Records"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel Search
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>        

    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize();
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',            
            academicYear: 'termReports/getAcademicYear',
            currentTerm: 'termReports/getCurrentTerm',
            formTeacherClass: 'termReports/getFormTeacherClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            pagination: 'termReports/getPagination',
            formClasses: 'termReports/getFormClassesList',
            admin: 'auth/getAdmin',
        }),

        studentName(){
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },
        classId(){
            return this.studentRecord.class_id;
        },
        recordSummary(){
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true},
                {title: 'Possible Attendance', model: 'possible_attendance', select: false, disabled: true},
                {title: 'No. of Times Absent', model: 'times_absent', select: false, disabled: false},
                {title: 'No. Times Late', model: 'times_late', select: false, disabled: false},
                {title: 'Attitude to Authority', model: 'attitude', select: true, disabled: false},
                {title: 'Application', model: 'responsibility', select: true, disabled: false},
                {title: 'Conduct', model: 'honor', select: true, disabled: false},
                {title: 'Music', model: 'co_curricular', select: true, disabled: false},
                {title: 'Theatre Arts', model: 'pe_dance', select: true, disabled: false},
            ]
        },        
        termName(){
            let termName = '';
            if(this.currentTerm == 1) termName =  'One';
            if(this.currentTerm == 2) termName = 'Two';
            if(this.currentTerm == 3) termName = 'Three'
            return termName
        }
    },
    watch: {
        studentRecord: {
            handler(){
                if(this.formTeacherClass != this.studentRecord.class_id && !this.admin){
                    // console.log('Not the form Teacher');
                    this.recordSummary.forEach(element => {
                        element.disabled = true;
                    })
                }
                else if(this.admin  || this.formTeacherClass == this.studentRecord.class_id){
                    console.log('admin authorized')
                    this.recordSummary.forEach(element => {
                        if(element.model != 'student_id')
                        element.disabled = false;
                    })
                }

                if(this.studentRecord.form_level > 2){
                    console.log
                    this.recordSummary.forEach(record => {
                        if(record.title == 'Theatre Arts') record.disabled = true
                    })
                }
              
            }
        }
    },
    data: () => ({
        academicPeriod: [
            { title: 'Academic Year', value: '' },
            { title: 'Term', value: '' },
        ],

        grades: [
            {grade: 'A', title: 'Excellent'},
            {grade: 'B', title: 'Very Good'},
            {grade: 'C', title: 'Good'},
            {grade: 'D', title: 'Fair'},
            {grade: 'E', title: 'Unsatisfactory'},
        ],        
        headers: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        options: {
            itemsPerPage: -1,
        },        
        disabled: false,        
        expand: false,
        overlay: false,
        search: '',
        width: 250,   
    }),
    methods: {
        ...mapActions({ 
            postTable1Record: 'termReports/postTable1Record',
            getTable2Records: 'termReports/getTable2Records', 
            setErrorResponse: 'termReports/setErrorResponse',            
        }),        
        ...mapMutations({         
           setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setPagination: 'termReports/setPagination',           
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects', 
            setExpand: 'termReports/setEditViewTermReportsExpand',          
        }),
        setRecordSummary(){
            //
        },
        async updateRecord(){
            console.log('updating...');
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //console.log(this.studentRecord);
            try{
                let response = await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
                console.log(response);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },
        initialize(){            
            this.academicPeriod[0].value = this.academicYear;
            this.academicPeriod[1].value = this.termName;            
        },

        searchStudent(){
            this.overlay = true;
            setTimeout(() => {
                this.expand = true;
            })
        },

        viewRecord(item){
            //console.log(item);
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
            let recordIndex = this.table1Records.indexOf(item);
            this.recordNavigation(++recordIndex);
        },

        cancelSearch(){
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
        },

        async recordNavigation(pagination){            
            this.setOverlay(true);
            this.setLoading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                const { data } = await this.getTable2Records();
                this.setTable2Records(data.table2_records);
                this.setStudentSubjects(data.student_subjects);                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.setLoading(false);            
        },

        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },       

        async previousRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.prev_page);
            console.log('previous');
        },

        nextRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.next_page);
        },
        
        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
        },

        setDisabledFields () {
            this.disabled = false;
            console.log(`form teacher class: ${this.formTeacherClass}`);
            this.recordSummary.forEach(element => {
                if(                           
                    element.model != 'student_id' &&
                    element.model != 'possible_attendance'
                )
                {                            
                    element.disabled = false;
                }
                else{                            
                    element.disabled = true;
                }
            })
            
            if(
                this.formTeacherClass != this.studentRecord.class_id
                && !this.admin
            )
            {                
                this.disabled = true;                    
                this.recordSummary.forEach(element => {
                    element.disabled = true;
                })
                
            }
                
        },

        validateInteger(event){
            if(event.keyCode < 48 || event.keyCode > 57)
            return event.preventDefault();
        }
        
    }
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    ::v-deep .v-list--dense .v-list-item{
        min-height: 32px;
    }

    .v-btn__content{
        font-size: 0.9em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 3px;        
    }

    ::v-deep .v-select__selections{
        height: 20px;       
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 16px;
        align-items: flex-start;
    }

    ::v-deep .v-select__selection--comma{
        margin: 0 4px 4px 0;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-chip.v-size--default{
        height: 20px;
        font-size: 11px;
    }    

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
            min-height: 8px;
        }

    ::v-deep .v-select.v-select--chips .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-select__slot .v-input__append-inner{
        padding-left: 0;
    }

    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 2px;
    }

    ::v-deep .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections{
        padding-left: 16px;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
        appearance: textfield;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        text-align: center;
    }
</style>