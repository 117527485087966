<template>
    <v-container class="pb-0">
        <v-data-table
            :items="table2Records"
            :loading="loading"
            :headers="headers"
            :height="height"            
            fixed-header           
            class="enter-marks-table"
            disable-pagination
            hide-default-footer                                       
        >
            <template v-slot:item="props">
                <Record
                    v-bind:studentRecord="props.item"
                />
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import Record from './EnterMarksSheetRecord';
import { mapGetters } from 'vuex';
export default {
    components: {
        Record,
    },
    created () {
        //this.initialize();
    },
    data: () =>({
        loading: false,
        height: "45vh",
        pagination: false,
        footer: false,                
        headers: [],       
        
    }),    
    computed:{
        ...mapGetters({            
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            lessonSelected: 'termReports/getLessonSelected',
        }),
    },
    watch: {
        lessonSelected: {
            handler(){
                this.initialize();
            }
        }
    },
    methods: { 
       initialize(){
        //    console.log(`Current Term: ${this.currentTerm}`);
        //    console.log(this.lessonSelected.formLevel);
           if(this.currentTerm == 2 && this.lessonSelected.formLevel < 5){
                // console.log('term 2 form level less than 5');
                this.headers = [
                    {text: '#', value: 'count', width: '45', sortable: false},
                    {text: 'Name', value: 'name', sortable: false,},
                    {text: 'Test 1 35%', value: 'test_1', align: 'center', width: '90', sortable: false,},            
                    {text: 'Test 2 35%', value: 'test_2', align: 'center', width: '90', sortable: false,},
                    {text: 'Project 30%', value: 'project', align: 'center', width: '90', sortable: false,},
                    {text: 'Coded Comment', value: 'codedComment1', align: 'center', width: '80', sortable: false,},            
                    {text: 'Coded Comment 1',  value: 'codedComment2', align: 'center', width: '80', sortable: false,},
                    {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
                ] 
           }
           else{
               console.log('term 2 form level greater than 5');
               this.headers = [
                    {text: '#', value: 'count', width: '45', sortable: false},
                    {text: 'Name', value: 'name', width: '160', sortable: false},
                    {text: 'Course', value: 'course', align: 'center', width: '80', sortable: false,},            
                    {text: 'Exam', value: 'exam', align: 'center', width: '80', sortable: false,},                    
                    {text: 'Coded Comment', value: 'codedComment1', align: 'center', width: '100', sortable: false,},            
                    {text: 'Coded Comment 1',  value: 'codedComment2', align: 'center', width: '100', sortable: false,},
                    {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
                ] 
           }
           
       }
    }
}
</script>

<style>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    
    th span{
        display: flex;
    }
</style>