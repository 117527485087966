import axios from 'axios'

export default{
    namespaced: true,
    state: { 
        overlay: false,       
        enterMarks: false,
        editViewTermReports: false,
        mainMenu: true,
        schoolName: process.env.VUE_APP_THEME_SCHOOL,
        lessons: [],
        lessonSelected: {},
        academicYearId: '',
        academicYear: '',
        currentYear: '',
        currentTerm: '',
        students: [],
        table2Records: [],
        markSheetOverlay: false,
        markSheetLoading: false,
        markSheetStudentSelected: '',
        markSheetFormLevel: '',
        expandLessonOptions: false,
        mainMenuButton: false,        
        codedComments: [],
        enterMarksRecordSelected: {},
        updatedTable2Record: {},
        errorMessage: '',
        modal: false,
        modalIcon: '',
        modalTitle: '',
        modalMessage: '',
        welcomeMessage: true,
        subjectStudentsRegistered: '-',
        subjectStudentsMarksEntered: '-',
        subjectStudentsTotal: '-',
        editViewTermReportsOverlay: false,
        editViewTermReportsLoading: false,
        editViewTermReportsExpand: false,
        editViewTermReportsTable2Records: [],
        editViewTermReportsStudentSubjects: [],
        editViewTermReportsSaveStatus: '',
        editViewTermReportsSaving: false,
        editViewTermReportsSaveError: false,
        editViewTermReportsSaved: false,
        formClasses: [],
        formClassesList: [],
        selectedFormClass: '',
        selectedStudentRecord: {},
        pagination: {},
        commentSheet: false,
        commentSheetEnterMarks: false,
        formTeacherComments: '',
        updatedTable1Record: {},
        formTeacherClass: '',
        bottomSheetModel: '',
        bottomSheetTitle: '',
        table1Records: [],
        overlayLessons: false,
        subjectWeightings: [],
        progressText: null,
        selectedTable2RecordIndex: -1,
        termsAvailable: [],
        src: null,
        overlayReport: false,
        expandTerms: false,        
        dialogReport: false,        
    },
    getters: {
        getEnterMarks(state){
            return state.enterMarks
        },
        getEditViewTermReports(state){
            return state.editViewTermReports
        },
        getMainMenu(state){
            return state.mainMenu
        },
        getPrimaryColor(state){
            return state.primaryColor
        },
        getColorDarken(state){
            return state.colorDarken
        },
        getSchoolName(state){
            return state.schoolName
        },
        getLessons(state){
            return state.lessons
        },
        getLessonSelected(state){
            return state.lessonSelected
        },
        getAcademicYearId(state){
            return state.academicYearId
        },
        getAcademicYear(state){
            return state.academicYear
        },
        getCurrentYear(state){
            return state.currentYear
        },
        getCurrentTerm(state){
            return state.currentTerm
        },
        getStudents(state){
            return state.students
        },
        getMarkSheetOverlay(state){
            return state.markSheetOverlay
        },
        getTable2Records(state){
            return state.table2Records
        },
        getExpandLessonOptions(state){
            return state.expandLessonOptions
        },
        getMainMenuButton(state){
            return state.mainMenuButton
        },
        getMarkSheetLoading(state){
            return state.markSheetLoading
        },
        getCodedComments(state){
            return state.codedComments
        },
        getEnterMarksRecordSelected(state){
            return state.enterMarksRecordSelected
        },
        getUpdatedTable2Record(state){
            return state.updatedTable2Record
        },
        getErrorMessage(state){
            return state.errorMessage
        },
        getModal(state){
            return state.modal
        },
        getModalIcon(state){
            return state.modalIcon
        },
        getModalTitle(state){
            return state.modalTitle
        },
        getModalMessage(state){
            return state.modalMessage
        },
        getWelcomeMessage(state){
            return state.welcomeMessage
        },
        getSubjectStudentsRegistered(state){
            return state.subjectStudentsRegistered
        },
        getSubjectStudentsMarksEntered(state){
            return state.subjectStudentsMarksEntered
        },
        getSubjectStudentsTotal(state){
            return state.subjectStudentsTotal
        },
        getMarkSheetStudentSelected(state){
            return state.markSheetStudentSelected
        },
        getMarkSheetFormLevel(state){
            return state.markSheetFormLevel
        },
        getEditViewTermReportsOverlay(state){
            return state.editViewTermReportsOverlay
        },
        getEditViewTermReportsLoading(state){
            return state.editViewTermReportsLoading
        },
        getEditViewTermReportsExpand(state){
            return state.editViewTermReportsExpand
        },
        getFormClasses(state){
            return state.formClasses
        },
        getFormClassesList(state){
            return state.formClassesList
        },
        getSelectedFormClass(state){
            return state.selectedFormClass
        },
        getSelectedStudentRecord(state){
            return state.selectedStudentRecord
        },
        getPagination(state){
            return state.pagination
        },
        getCommentSheet(state){
            return state.commentSheet
        },

        getCommentSheetEnterMarks (state) {
            return state.commentSheetEnterMarks
        },
        
        getFormTeacherComments(state){
            return state.formTeacherComments
        },
        getEditViewTermReportsTable2Records(state){
            return state.editViewTermReportsTable2Records
        },
        getEditViewTermReportsStudentSubjects (state) {
            return state.editViewTermReportsStudentSubjects
        },
        getUpdatedTable1Record(state){
            return state.updatedTable1Record
        },
        getEditViewTermReportsSaveStatus(state){
            return state.editViewTermReportsSaveStatus
        },
        getEditViewTermReportsSaving(state){
            return state.editViewTermReportsSaving
        },
        getEditViewTermReportsSaveError(state){
            return state.editViewTermReportsSaveError
        },
        getEditViewTermReportsSaved(state){
            return state.editViewTermReportsSaved
        },
        getFormTeacherClass(state){
            return state.formTeacherClass
        },
        getOverlay(state){
            return state.overlay
        },
        getBottomSheetModel(state){
            return state.bottomSheetModel
        },
        getBottomSheetTitle(state){
            return state.bottomSheetTitle
        },
        getTable1Records(state){
            return state.table1Records
        },
        getOverlayLessons(state){
            return state.overlayLessons
        },

        getSubjectWeightings (state) {
            return state.subjectWeightings
        },

        getProgressText (state) {
            return state.progressText
        },

        getSelectedTable2RecordIndex (state) {
            return state.selectedTable2RecordIndex
        },

        getTermsAvailable (state) {
            return state.termsAvailable
        },

        getSrc (state) {
            return state.src
        },

        getOverlayReport (state) {
            return state.overlayReport
        },

        getExpandTerms (state) {
            return state.expandTerms
        },       
        
        getDialogReport (state) {
            return state.dialogReport
        },

    },
    mutations: {
        setEnterMarks(state, value){
            state.enterMarks = value
        },
        setEditViewTermReports(state, value){
            state.editViewTermReports = value
        },
        setMainMenu(state, value){
            state.mainMenu = value
        },
        setLessons(state, value){
            state.lessons = value
        },
        setLessonSelected(state, value){
            state.lessonSelected = value
        },
        setAcademicYearId(state, value){
            state.academicYearId = value
        },
        setAcademicYear(state, value){
            state.academicYear = value
        },
        setCurrentYear(state, value){
            state.currentYear = value
        },
        setCurrentTerm(state, value){
            state.currentTerm = value
        },
        setStudents(state, value){
            state.students = value
        },
        setMarkSheetOverlay(state, value){
            state.markSheetOverlay = value
        },
        setTable2Records(state, value){
            state.table2Records = value
        },
        setExpandLessonOptions(state, value){
            state.expandLessonOptions = value
        },
        setMainMenuButton(state, value){
            state.mainMenuButton = value
        },
        setMarkSheetLoading(state, value){
            state.markSheetLoading = value
        },
        setCodedComments(state, value){
            state.codedComments = value
        },
        setEnterMarksRecordSelected(state, value){
            state.enterMarksRecordSelected = value
        },
        setUpdatedTable2Record(state, value){
            state.updatedTable2Record = value
        },
        setErrorMessage(state, value){
            state.errorMessage = value
        },
        setModal(state, value){
            state.modal = value
        },
        setModalTitle(state, value){
            state.modalTitle = value
        },
        setModalIcon(state, value){
            state.modalIcon = value
        },
        setModalMessage(state, value){
            state.modalMessage = value
        },
        setWelcomeMessage(state, value){
            state.welcomeMessage = value
        },
        setSubjectStudentsTotal(state, value){
            state.subjectStudentsTotal = value
        },
        setSubjectStudentsRegistered(state, value){
            state.subjectStudentsRegistered = value
        },
        setSubjectStudentsMarksEntered(state, value){
            state.subjectStudentsMarksEntered = value
        },
        setMarkSheetStudentSelected(state, value){
            state.markSheetStudentSelected = value
        },
        setMarkSheetFormLevel(state, value){
            state.markSheetFormLevel = value
        },
        setEditViewTermReportsOverlay(state, value){
            state.editViewTermReportsOverlay = value
        },
        setEditViewTermReportsLoading(state, value){
            state.editViewTermReportsLoading = value
        },
        setEditViewTermReportsExpand(state, value){
            state.editViewTermReportsExpand = value
        },
        setFormClasses(state, value){
            state.formClasses = value
        },
        setFormClassesList(state, value){
            state.formClassesList = value
        },
        setSelectedFormClass(state, value){
            state.selectedFormClass = value
        },
        setSelectedStudentRecord(state, value){
            state.selectedStudentRecord = value
        },
        setPagination(state, value){
            state.pagination = value
        },
        setCommentSheet(state, value){
            state.commentSheet = value
        },

        setCommentSheetEnterMarks (state, value) {
            state.commentSheetEnterMarks = value
        },

        setFormTeacherComments(state, value){
            state.formTeacherComments = value
        },
        setEditViewTermReportsTable2Records(state, value){
            state.editViewTermReportsTable2Records = value
        },
        setEditViewTermReportsStudentSubjects(state, value){
            state.editViewTermReportsStudentSubjects = value
        },
        setUpdatedTable1Record(state, value){
            state.updatedTable1Record = value
        },
        setEditViewTermReportsSaveStatus(state, value){
            state.editViewTermReportsSaveStatus = value
        },
        setEditViewTermReportsSaving(state, value){
            state.editViewTermReportsSaving = value
        },
        setEditViewTermReportsSaveError(state, value){
            state.editViewTermReportsSaveError = value
        },
        setEditViewTermReportsSaved(state,value){
            state.editViewTermReportsSaved = value
        },
        setFormTeacherClass(state, value){
            state.formTeacherClass = value
        },
        setOverlay(state, value){
            state.overlay = value
        },
        setBottomSheetModel(state, value){
            state.bottomSheetModel = value
        },
        setBottomSheetTitle(state, value){
            state.bottomSheetTitle = value
        },
        setTable1Records(state, value){
            state.table1Records = value
        },
        setOverlayLessons(state, value){
            state.overlayLessons = value
        },
        setSubjectWeightings(state, value){
            state.subjectWeightings = value
        },

        setProgressText (state, value) {
            state.progressText = value
        },

        setSelectedTable2RecordIndex (state, value) {
            state.selectedTable2RecordIndex = value
        },

        setTermsAvailable (state, value) {
            state.termsAvailable = value
        },

        setExpandTerms (state, value) {
            state.expandTerms = value
        },                

        setDialogReport (state, value) {
            state.dialogReport = value
        },

        setSrc (state, value) {
            state.src = value
        },

        setOverlayReport (state, value) {
            state.overlayReport = value
        }
       
    },
    actions: {

        async getLessons({ commit, dispatch }, id){
            let response = null, url = null;
            
            try {
                url = '/api/teacher-lessons/' + id;
                const teacherLessonsPromise = axios.get(url);

                //url = '/api/coded-comments';
                const codedCommentsPromise = dispatch('getCodedComments');

                response = await teacherLessonsPromise;
                //console.log(response);
                commit('setLessons', response.data);

                response = await codedCommentsPromise;
                commit('setCodedComments', response.data);
                                         
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
            }
        },

        getCodedComments () {
            const url = '/api/coded-comments';
            return axios.get(url);
        },

        async getCurrentAcademicPeriod({ commit }){
            let url = '/api/current-period';
            try {
                let { data: { current_term: currentTerm } } = await axios.get(url); 
                let academicYear = currentTerm.academic_year_id;
                commit('setAcademicYearId', academicYear);
                commit('setAcademicYear', academicYear.toString().substr(0, 4) + '-' + academicYear.toString().substr(4))
                commit('setCurrentYear', academicYear.toString().substr(0,4));
                commit('setCurrentTerm', currentTerm.term);
            } catch (error) {
                console.log(error);
            }
        },

        async getStudents({ commit, getters, dispatch }, id){
            let response = null;

            commit('setProgressText', 'Getting Students..');

            let url = '/api/teacher-lesson-students/' 
            + getters.getLessonSelected.className + '/' 
            + getters.getCurrentYear + '/'
            + getters.getCurrentTerm + '/'
            + getters.getLessonSelected.subjectCode + '/'
            + id;

            try {
                const studentPromise = axios.get(url);
                response = await studentPromise
                commit('setProgressText', 'Setting up Mark Sheet..');                                
                dispatch('setTable2Records', response.data.data);                
                commit('setMarkSheetLoading', false);
                commit('setMarkSheetOverlay', false);
                commit('setProgressText', '');                               
                commit('setSubjectStudentsTotal', response.data.total);               
                commit('setSubjectStudentsRegistered', response.data.registered);
                commit('setSubjectStudentsMarksEntered', response.data.entered);
                commit('setSubjectWeightings', response.data.weightings);

            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
                
                commit('setMarkSheetLoading', false);
                commit('setExpandLessonOptions', true);
                commit('setModalTitle', 'Error Occured.');
                commit('setModalIcon', 'error');
                commit('setModalMessage', error.response.statusText);
                commit('setModal', true);
            }
        },
        
        async getCodedCommentsList({ commit }){
            //console.log('getting coded comments...')
            let url = '/api/coded-comments';
            try {
                let response = await axios.get(url);
                commit('setCodedComments', response.data);
                // console.log(response);
            } catch (error) {
                console.log(error);
            }
        },

        saveTable2Record({ getters }){
            //console.log(getters.getUpdatedTable2Record);
            let url = '/api/table2';
            return axios.post(url, getters.getUpdatedTable2Record);
        },

        getTable1Record({ getters }, page_url){            
            let url = '/api/students-registered/'
            + getters.getCurrentYear + '/'
            + getters.getCurrentTerm + '/'
            + getters.getSelectedFormClass;
            
            page_url = page_url || url;
            return axios.get(page_url);           
        },
        
        getTable2Records({ getters }){
            let url = '/api/student-table2-records/'
            + getters.getSelectedStudentRecord.student_id + '/'
            + getters.getCurrentYear + '/'
            + getters.getCurrentTerm + '/'
            + getters.getSelectedStudentRecord.class_id; 
            return axios.get(url);
        },
                
        getFormClasses(){
            let url = '/api/form-classes';
            try{
                return axios.get(url);
            } catch (error) {
                console.log(error);
                this.setErrorResponse(error);
            }
        },

        setErrorResponse({ commit },error){
            console.log(error.response);
            if(error.response){                
                commit('setModalTitle', 'Error Occured.');
                commit('setModalIcon', 'error');
                commit('setModalMessage', error.response.data.message);
                commit('setModal', true);
            }
            else{
                commit('setModalTitle', 'Error Occured.');
                commit('setModalIcon', 'error');
                commit('setModalMessage', 'An Error Occured Please contact your administrator');
                commit('setModal', true);
            }
        },

        setTable2Records({ commit }, records){
            let table2Records = [];
            //console.log(records);
            let count = 0;                         
            records.forEach(record =>{
                let studentRecord = {};
                studentRecord.count = ++count;
                studentRecord.name = record.first_name + ' ' + record.last_name;                                            
                studentRecord = Object.assign(studentRecord, record);
                table2Records.push(studentRecord);                    
            })
            commit('setTable2Records', table2Records);
            //console.log(getters.termMarksRecords);
        },

        async postTable1Record({ getters }){
            let url = "/api/table1";
            let record = getters.getSelectedStudentRecord;
            let table1Record = {};
            Object.keys(record).forEach(key => {
                if(key != 'first_name' &&
                key != 'last_name' &&
                key != 'picture' &&
                key != 'form_level') table1Record[key] = record[key];
            })
            console.log(table1Record);
            return axios.post(url, table1Record);
        },

        getAssignedFormTeacherClass({ getters }, id){
            let url = '/api/form-teacher-class/' + id 
            + '/' + getters.getAcademicYearId;
            //console.log(url);
            return axios.get(url);
        },

        dowloadExcelMarkSheet ({ getters }) {
            let urlArray = [];
            const baseUrl = getters.getSrc.baseUrl + "-excel";            
            urlArray.push(baseUrl);
            Object.keys(getters.getSrc).forEach(key => {
                if(key != "report" && key != "baseUrl"){
                    urlArray.push(getters.getSrc[key]);
                }
            })
            let url = urlArray.join("/");
            console.log(url);
            return axios({
                method: 'get',
                url: url,
                responseType: 'arraybuffer',
            });
        },
        
        
    }
}    