import { render, staticRenderFns } from "./EnterMarksLessonAdmin.vue?vue&type=template&id=f7b60674&scoped=true&"
import script from "./EnterMarksLessonAdmin.vue?vue&type=script&lang=js&"
export * from "./EnterMarksLessonAdmin.vue?vue&type=script&lang=js&"
import style0 from "./EnterMarksLessonAdmin.vue?vue&type=style&index=0&id=f7b60674&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7b60674",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCard,VDataTable,VExpandTransition,VIcon,VList,VListGroup,VListItem,VListItemContent,VListItemTitle,VTextField,VToolbar,VToolbarTitle,VWindow,VWindowItem})
