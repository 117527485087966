<template>
    <v-card flat>
        <v-card-title>
            <v-btn
                color="primary"
                outlined
                small
                @click="addSubjectMenu"
            >
                Add New Subject
            </v-btn>            
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details=""
                clearable
            ></v-text-field>
        </v-card-title>               
        <v-data-table
            class="mt-10"
            :headers="headers"
            :items="subjects"
            :search="search"
            fixed-header
            height="40vh"
            :loading="loading"            
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn                    
                    outlined
                    color="primary"
                    @click="editSubject(item)"
                    class="mr-2"
                    small
                >                    
                    <v-icon
                        left
                        medium
                        class="mr-2"
                        
                    >
                        mdi-pencil
                    </v-icon>
                        Edit Subject
                </v-btn>

                <v-btn                    
                    outlined
                    color="primary"
                    @click="deleteSubjectMenu(item)"
                    small
                >                    
                    <v-icon
                        left
                        medium
                        class="mr-2"
                        
                    >
                        mdi-delete
                    </v-icon>
                        Delete Subject
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog
            v-model="dialog"
            max-width="400px"
            persistent
        >
            <v-card>
                <v-card-title
                    class="pr-1"
                >
                    <span>{{ cardTitle }}</span>
                    <v-btn
                        icon
                        color="primary"
                        class="ml-auto"
                        @click="cancel"
                    >
                        <v-icon                            
                        >mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="selectedSubject.id"
                                hide-details
                                label="Subject ID"
                                outlined
                                dense
                                disabled
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="selectedSubject.title"
                                hide-details
                                label="Subject Title"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="selectedSubject.abbr"
                                hide-details
                                label="Subject Abbreviation"
                                outlined
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-6 pb-4">
                    <span 
                        style="font-size:0.7rem;"
                    >{{ updateMessage }}</span>
                    <v-expand-transition>
                        <v-icon
                            small
                            color="green" 
                            v-show="saved"
                            class="mx-2"
                        >
                            mdi-check-all
                        </v-icon>
                    </v-expand-transition>
                    <v-expand-transition>    
                        <v-icon
                            small
                            color="red"
                            v-show="saveError"
                            class="mx-2"
                        >
                            mdi-alert-circle
                        </v-icon>
                    </v-expand-transition>    
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        small
                        @click="cancel"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="!newSubject"
                        color="primary"
                        dark
                        small
                        @click="updateSubject"
                    >
                        Update Subject
                    </v-btn>
                    <v-btn
                        v-if="newSubject"
                        color="primary"
                        dark
                        small
                        @click="addSubject"
                    >
                        Add Subject
                    </v-btn>
                </v-card-actions>

                <v-overlay
                    absolute
                    :value="overlay"                    
                    color="grey lighten-5"
                    opacity="0.9" 
                >
                    <v-progress-circular
                        indeterminate 
                        size="64"                        
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>    
        </v-dialog>

        <v-dialog
            v-model="dialogConfirmDelete"
            max-width="400px"
            persistent
        >
            <v-card>
                <v-card-title>
                    {{ cardTitleDelete }}
                </v-card-title>
                <v-card-actions
                    class="pa-4"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        small
                        @click="cancelDelete"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        dark
                        small
                        @click="confirmDelete"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>

                <v-overlay
                    absolute
                    :value="overlayDeleteConfirm"                    
                    color="grey lighten-5"
                    opacity="0.9" 
                >
                    <v-progress-circular
                        indeterminate 
                        size="64"                        
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            timeout="3000"
        >
            {{ snackText }}

            <template v-slot:action="{ attrs }">
                <v-btn                    
                    text
                    color="white"
                    v-bind="attrs"
                    small
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>   
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        subjects: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },

    created: function () {
        if(this.subjects.length == 0) this.loading = true;
        else this.loading = false;
    },    

    data: () => ({
        headers: [
            {text: 'ID', sortable: 'false', value: 'id', width: '70'},
            {text: 'Subject', align: 'start', sortable: 'false', value: 'title', width: '200'},
            {text: 'Abbreviation', align: 'start', sortable: 'false', value: 'abbr', width: '150'},
            {text: '', value: 'actions', sortable: 'false'}
        ],        
        search: '',
        loading: false,
        dialog: false,
        cardTitle: '',
        selectedSubject: {},
        overlay: false,
        updateMessage: '',
        saved: false,
        saveError: false,
        newSubject: false,
        defaultSubject: {
            id: '',
            title: '',
            abbr: ''
        },
        dialogConfirmDelete: false,
        cardTitleDelete: '',
        overlayDeleteConfirm: false,
        snackbar: false,
        snackText: '',
        snackColor: 'primary',
        selectedIndex: -1,
    }),

    watch: {
        subjects: {
            handler () {
                this.loading = false;
            }
        }
    },

    methods: {
        ...mapMutations({
            setSelectedSubject: 'admin/setSelectedSubject',
        }),

        ...mapActions({
            postSubject: 'admin/postSubject',
            deleteSubject: 'admin/deleteSubject',
        }),

        editSubject (item) {
            this.cardTitle = item.title;
            this.selectedSubject = item;
            this.selectedIndex = this.subjects.indexOf(item);
            this.setSelectedSubject(item);
            this.dialog = true;
        },

        deleteSubjectMenu (item) {            
            this.selectedSubject = item;
            this.selectedIndex = this.subjects.indexOf(item);
            this.setSelectedSubject(item);
            this.dialogConfirmDelete = true;
            this.cardTitleDelete = `Delete ${item.title} ?`            
        },

        cancelDelete () {
            this.selectedIndex = -1;
            this.selectedSubject = Object.assign({}, this.defaultSubject);
            this.dialogConfirmDelete = false;
        },

        async confirmDelete () {
            this.overlayDeleteConfirm = true;
            try {
                let response = await this.deleteSubject();
                this.snackbar = true;
                this.snackColor = 'primary';
                this.snackText = `${this.selectedSubject.title} deleted`;
                this.$emit('update-subjects');
                console.log(response);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackbar = true;
                this.snackColor = 'red';
                this.snackText = "An error has occured. Subject cannot be deleted";
            }
            this.overlayDeleteConfirm = false;
            this.cancelDelete();
        },

        cancel () {
            this.dialog = false;
            this.selectedIndex = -1;
            this.selectedSubject = Object.assign({}, this.defaultSubject);
            this.newSubject = false;
            this.updateFeedback();
        },

        async updateSubject () {
            this.overlay = true;
            this.updateFeedback()
            try {
                const response = await this.postSubject();
                console.log(response);
                this.$emit('update-subjects');
                if(this.selectedIndex == -1) this.updateFeedback("new");
                else this.updateFeedback("saved");
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.updateFeedback("error");
            }
            this.overlay = false;
        },

        updateFeedback (action = "clear") {
            console.log(action);
            if(action == "saved"){
                this.updateMessage = "Update Successfull";
                this.saveError = false;
                setTimeout(() => {
                    this.saved = true;
                }, 600);
            }
            else if (action == "error") {
                this.updateMessage = "An Error Occured.";
                this.saved = false;
                setTimeout(() => {
                    this.saveError = true;
                }, 600);
            }
            else if (action == "clear") {
                this.saved = false;
                this.saveError = false;
                this.updateMessage = '';
            }
            else if (action == "new") {
                this.updateMessage = "Subject Added";
                this.saveError = false;
                setTimeout(() => {
                    this.saved = true;
                }, 600);
            }
            
        },
        
        addSubjectMenu () {
            this.newSubject = true;
            this.cardTitle = "New Subject";                       
            this.selectedSubject = Object.assign({}, this.defaultSubject);
            this.dialog = true;
        },

        addSubject () {
            this.setSelectedSubject(this.selectedSubject);
            this.updateSubject();
        }
    }
}
</script>